@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Act as a border */
  .card-wrapper {
    @apply relative overflow-hidden rounded-2xl;
  }

  /* Gradient */
  .card-wrapper::before {
    background: conic-gradient(
      rgba(244, 114, 182, 0.4) 0deg,
      rgba(192, 132, 252, 0.4) 0deg,
      transparent 80deg
    );

    @apply absolute left-[-25%] top-[-25%] h-[150%] w-[150%] animate-border-spin content-[''];
  }

  /* Body */
  .card-content {
    @apply absolute left-[1px] top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)] rounded-2xl bg-gradient-to-b from-slate-800 to-slate-900;
  }
}

.nft-gifs {
  height: 100%;
  width: 100%;
  border-radius: 999999;
}

.snackbar-zIndex {
  z-index: 9999999999 !important;
}

.landing-swiper .swiper-slide {
  width: 100% !important;
}
.landing-swiper .swiper-slide {
  max-width: 100% !important;
}

.swiper-income .swiper-slide {
  display: flex;
  align-items: center;
  transform: scale(0.8) !important;
  border-radius: 1.5rem !important;
}

.swiper-income .swiper-slide.swiper-slide-active {
  transform: scale(1.1) !important;
  border-radius: 1.5rem !important;
  margin-right: 20px !important;
  margin-left: 6px !important;
}

.swiper-income .swiper-slide.swiper-slide-activ img {
  border-radius: 1.5rem !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .swiper-income .swiper-slide.swiper-slide-active {
    transform: scale(1) !important;
    border-radius: 1.5rem !important;
  }
}
